import React from "react";

function Timeline(props) {
  const { children } = props;
  
  const renderedChildren = React.Children.toArray(children);

  return <div className="timeline">{renderedChildren}</div>;

}

export default Timeline;