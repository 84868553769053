import { gql } from "@apollo/client";

export const GET_STAGES = gql`
  query product_by_id($id: ID!) {
    product_by_id(id: $id) {
      stages {
        id
        title
        output
        output_icon {
          id
        }
        location
        by
        logo_by {
          id
        }
        evidences {
          evidence_id {
            title
            subtitle
            icon {
              id
            }
            logo {
              id
            }
            description
            evidence {
              id
            }
            ots {
              id
            }
            link
          }
        }
        sort
      }
    }
  }
`;
